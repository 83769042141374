html,
body {
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Bungee Shade', cursive;
  font-family: 'Oswald', sans-serif;
  background-color: #f2f1ed;
}

#content {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
}

#center {
  max-width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

#center > * {
  flex: none;
  margin: 0 1rem;
}

.controls {
  text-align: right;
  white-space: nowrap;
}

#modes {
  display: none;
}

#modesRadio {
  text-align: center;
}

#eyes {
  display: flex;
  flex-wrap: nowrap;
}

#eyes canvas + canvas {
  margin-left: 1px;
}

#loading {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 100vh;
  background-color: #f2f1ed;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
}

h1 {
  color: #283949;
  font-size: 2.5rem;
  color: slategrey;
  font-family: 'Bungee Shade', cursive;
  margin-bottom: 0;
}

h2 {
  margin-top: 0rem;
  margin-bottom: 2rem;
  font-size: 1rem;
}

@media (max-width: 1000px) {
  h1 {
    font-size: 2rem;
  }
}

#tooSmall {
  display: none;
}

@media (max-width: 750px) {
  #tooSmall {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 100vh;
    z-index: 50;
    background-color: white;
    color: maroon;
    font-weight: bold;
    font-size: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

h3 {
  color: #283949;
  margin-top: 0.6rem;
  margin-bottom: 0.25rem;
}

h3:first-of-type {
  margin-top: 0;
}

a {
  color: #7786a3;
}

p {
  margin-top: 0.25rem;
}

#modesRadio {
  margin-bottom: 0.5rem;
}

#remaining {
  flex: 1;
}

#credit {
  padding: 1rem 0;
  justify-self: flex-end;
}

#error {
  display: none;
  margin: 2rem 0;
  font-weight: bold;
}

#error span {
  color: maroon;
}

.hidden {
  position: absolute;
  z-index: -100;
  visibility: hidden;
}
