html, body {
  margin: 0;
  padding: 0;
}

body {
  background-color: #f2f1ed;
  font-family: Oswald, sans-serif;
}

#content {
  height: 100vh;
  flex-direction: column;
  align-items: center;
  display: flex;
  overflow-y: auto;
}

#center {
  max-width: 100%;
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

#center > * {
  flex: none;
  margin: 0 1rem;
}

.controls {
  text-align: right;
  white-space: nowrap;
}

#modes {
  display: none;
}

#modesRadio {
  text-align: center;
}

#eyes {
  flex-wrap: nowrap;
  display: flex;
}

#eyes canvas + canvas {
  margin-left: 1px;
}

#loading {
  height: 100vh;
  z-index: 100;
  background-color: #f2f1ed;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

h1 {
  color: #283949;
  color: #708090;
  margin-bottom: 0;
  font-family: Bungee Shade, cursive;
  font-size: 2.5rem;
}

h2 {
  margin-top: 0;
  margin-bottom: 2rem;
  font-size: 1rem;
}

@media (max-width: 1000px) {
  h1 {
    font-size: 2rem;
  }
}

#tooSmall {
  display: none;
}

@media (max-width: 750px) {
  #tooSmall {
    height: 100vh;
    z-index: 50;
    color: maroon;
    background-color: #fff;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    font-weight: bold;
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
}

h3 {
  color: #283949;
  margin-top: .6rem;
  margin-bottom: .25rem;
}

h3:first-of-type {
  margin-top: 0;
}

a {
  color: #7786a3;
}

p {
  margin-top: .25rem;
}

#modesRadio {
  margin-bottom: .5rem;
}

#remaining {
  flex: 1;
}

#credit {
  justify-self: flex-end;
  padding: 1rem 0;
}

#error {
  margin: 2rem 0;
  font-weight: bold;
  display: none;
}

#error span {
  color: maroon;
}

.hidden {
  z-index: -100;
  visibility: hidden;
  position: absolute;
}

/*# sourceMappingURL=index.998f08a8.css.map */
